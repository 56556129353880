const topicSlug = {
  nasTym: "our-team/",
  pripadoveStudie: "case-studies/",
};

export const Content = {
  nasTym: {
    title: "Our team",
    slug: topicSlug.nasTym,
    description: `You will cooperate mostly with certified tax advisors and qualified accountants. Our cooperation is based on the precise outcome. Simultaneously, we  focus on meeting the planned deadlines so that you could rely on us. Our whole team works as a unit in the most efficient way possible, considering not only the professional specialisation but also the personal qualities emphasising the development of soft skills. As a result, you can expect accurate tax and accounting output, professional behaviour and easy communication at the same time.`,
    moreEN: true
  },
  pripadoveStudie: {
    title: "Case studies",
    slug: topicSlug.pripadoveStudie,
    description: `Throughout our careers we have reached experience with VAT registration of hundreds of taxpayers, with tax solutions of restructuring, ERP system implementations and also analysis with transfer pricing. We will show you the specific projects and results in our case studies.`,
    moreEN: true
  },
};
export default Content;
