import React from 'react'
import Layout from '../../../components/EN/LayoutEN'
import PageHeader from '../../../components/PageHeader/PageHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import AccordionTemplate from '../../../components/Services/AccordionTemplate'
import Content from '../../../../content/accordion/en/o-nas'
import { graphql } from 'gatsby'
import { H2Blue, UnderlineYellow } from '../../../components/Typography'
const Onas = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <H2Blue>Clients</H2Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '5.8rem' }}>
          Our clients are mostly small or medium-sized legal entities for whom
          the outsourcing of accounting and tax services is the most efficient
          solution. We also search start-ups cooperation as we can relieve them
          of their accounting and tax obligations, administrative burden at the
          beginning of their business, we help them with processes setup and
          allow them to concentrate on their business development. Our work is
          carried out precisely and effectively with the use of advanced
          software programs with cloud solution that also enables to separate
          administration from accounting and furthermore comes up with a very
          clear and well-arranged data export.
        </p>
        <Accordion
          items={[
            {
              title: Content.nasTym.title,
              description: <AccordionTemplate {...Content.nasTym} />,
            },
            {
              title: Content.pripadoveStudie.title,
              description: <AccordionTemplate {...Content.pripadoveStudie} />,
            },
          ]}
        />
      </div>
    </Layout>
  )
}

export default Onas

export const pageQuery = graphql`
  query OnasEN {
    page: markdownRemark(frontmatter: { slug: { eq: "en/about-us" } }) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
